 /* kyc */
 .kyc-header {
    padding: 10px;
    border-bottom: 1px solid rgb(202, 200, 200);
    display: flex;
    justify-content: space-between;
    background-color: white;
    position: fixed;
    z-index: 2000;
    height: 50px;
    top: 0;
    width: 100%;
    overflow: hidden;
}

.kyc-signup-header {
    padding: 10px;
    border-bottom: 1px solid rgb(202, 200, 200);
    font-size: 18px;
    background-color: white;
    font-weight: bold;
    height: 50px;
    margin-left: 16;
    display: flex;
    align-items: center;
    z-index: 2000;
    position: fixed;
    top: 0;
    width: 100%;
}

.header-reload {
    padding-top: 4px;
    padding-bottom: 4px;
    color: white;
    display: flex;
    width: 69px;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(146, 144, 144);
    border-radius: 12px;
}

video {
    width: 100%    !important;
    height: 100%   !important;
    object-fit: fill;
}

.fa-globe {
    text-shadow: 3px 6px rgba(255,165,0,.75)
}

.kyc-logo {
    width: 70%;
    height: 40%;
}

/* kyc signup  */

.kyc-signup-image {
    width: 132px;
    height: 134px;
}


 #frm-control {
    width: 100%;
    background-color: #EFF2F5;
    margin-bottom: 10px;
    z-index: 0;
}

.frm-radio {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

#dtp-dob {
    background-color: #EFF2F5;
    border-left: none;
    border-right: none;
}

#dtp-filter {
    background-color: #EFF2F5;
}

.image-wrapper {
    width:50%;
    border-radius: 4px;
     margin-right: 6px;
    position: relative;
    z-index: 0;
}
.image-wrapper label {
    position: absolute;
    font-size: 14px;
    background-color: #00ADFF;
    color: white;
    padding: 2px 6px;
    border-top-left-radius: 4px;
}


/* kyc signup detail  */

/* .kyc-header {
    overflow: hidden;
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
} */

.detail-info {
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 10px;
    margin-bottom: 14px;
    border-bottom: 1px solid #697D95;
}


/* kyc wait */
.main-slider {
    padding: 0px 12px;
    margin-bottom: 20px;
}










