.box-shadow-sm {
   box-shadow: 0 1px 2px rgb(0 0 0 / 0.5);
   border: "none";
}

.box-add-card {
   box-shadow: 0 1px 2px rgb(0 0 0 / 0.5);
   border: "none";
   width: 68%;
   height: 90px;
   border-radius: 6px;
   align-items: center;
   display: flex;
   justify-content: space-between;
   color: #2F80ED;
}

.box-scan {
   background-color: #00ADFF;
   width: 30%;
   height: 90px;
   border-radius: 6px;
   display: flex;
   justify-content: center;
   align-items: center;
   box-shadow: 0 1px 2px rgb(0 0 0 / 0.5);
   border: "none"
}

.box-shadow-md {
   box-shadow: 0 1px 2px rgb(0 0 0 / 0.5);
   border: "none"

}

.box-shadow-see-all {
   box-shadow: 0 1px 2px rgb(0 0 0 / 0.5);
   border: "none";
   width: 90%;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #00ADFF;
   font-weight: bold;
   padding-top: 8px;
   padding-bottom: 8px;
}

.home-header {
   padding: 10px;
   border-bottom: 1px solid rgb(202, 200, 200);
   font-size: 18px;
   background-color: white;
   font-weight: bold;
   height: 50px;
   margin-left: 16;
   display: flex;
   align-items: center;
   z-index: 2000;
   position: fixed;
   top: 0;
   width: 100%;
}

.home-history-header {
   padding: 10px;
   border-bottom: 1px solid rgb(202, 200, 200);
   font-size: 18px;
   background-color: white;
   font-weight: bold;
   height: 50px;
   margin-left: 16;
   display: flex;
   align-items: center;
   justify-content: space-between;
   z-index: 2000;
   position: fixed;
   top: 0;
   width: 100%;
}

.qr-header {
   padding: 10px;
   border-bottom: 1px solid rgb(202, 200, 200);
   font-size: 18px;
   background-color: white;
   font-weight: bold;
   height: 50px;
   margin-left: 16;
   display: flex;
   align-items: center;
   justify-content: space-between;
   z-index: 2000;
   position: fixed;
   top: 0;
   width: 100%;
}

#form-date-control {
   background-color: #EFF2F5;
   border: "1px solid";
   border-radius: 4px;
   padding-top: 5px;
   padding-bottom: 5px;

   margin-bottom: 10px;
   z-index: 0;
}

#form-date-control:focus {
   border-color: #86B7FE;
}

video {
   max-width: 100%;
   max-height: 100%;
}

/*box scan size*/
.scanner-container {
   position: relative;
   height: 80vh;
   width: 100%;
}

.overlay-element {
   position: absolute;
   top: 0;
   width: 100%;
   height: 100%;
   background: rgba(30, 30, 30, 0.5);
   -webkit-clip-path: polygon(0% 0%,
         0% 100%,
         20% 100%,
         20% 20%,
         80% 20%,
         80% 80%,
         20% 80%,
         20% 100%,
         100% 100%,
         100% 0%);
   clip-path: polygon(0% 0%,
         0% 100%,
         20% 100%,
         20% 20%,
         80% 20%,
         80% 80%,
         20% 80%,
         20% 100%,
         100% 100%,
         100% 0%);
}

.laser {
   width: 60%;
   margin-left: 20%;
   background-color: tomato;
   height: 1px;
   position: absolute;
   top: 40%;
   z-index: 2;
   box-shadow: 0 0 4px red;
   -webkit-animation: scanning 2s infinite;
   animation: scanning 2s infinite;
}

@-webkit-keyframes scanning {
   50% {
      -webkit-transform: translateY(75px);
      transform: translateY(75px);
   }
}

@keyframes scanning {
   50% {
      -webkit-transform: translateY(75px);
      transform: translateY(75px);
   }
}

.qr-background {
   background: linear-gradient(30.09deg, #16AFF1 3.61%, #8FDDFF 28.22%, #0AB5FE 49.35%, #2CC0FF 61.95%, #A4E4FF 70.78%, #17B2F9 83.84%);
   padding: 10px;
   border-radius: 6px;
}

#dtp-filter-custom {
   display: block;
   width: 100%;
   height: calc(1.5em + 0.75rem + 2px);
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   border-left: none;
   border-top-right-radius: 6px;
   border-bottom-right-radius: 6px;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#dtp-filter-custom:focus {
   color: #495057;
   background-color: #fff;
   border-color: #80bdff;
   outline: 0;
   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
 }