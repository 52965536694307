 .pin-button {
    height: 88px; 
    width: 88px;
    background-color: #EFEFEF;
    border-radius: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
}

