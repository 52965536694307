.custom-appbar .back-icon:active {
    background-color: rgb(185, 179, 179);
}

.custom-appbar {
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 17px;
    z-index: 2000;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    position: sticky;
    top: 0;
}