.box-shadow-sm {
   box-shadow: 0 1px 2px rgb(0 0 0 / 0.5);
   border: "none";
}

.box-add-card {
   box-shadow: 0 1px 2px rgb(0 0 0 / 0.5);
   border: "none";
   width: 68%;
   height: 90px;
   border-radius: 6px;
   align-items: center;
   display: flex;
   justify-content: space-between;
   color: #2F80ED;
}

.box-scan {
   background-color: #00ADFF;
   width: 30%;
   height: 90px;
   border-radius: 6px;
   display: flex;
   justify-content: center;
   align-items: center;
   box-shadow: 0 1px 2px rgb(0 0 0 / 0.5);
   border: "none"
}

.box-shadow-md {
   box-shadow: 0 1px 2px rgb(0 0 0 / 0.5);
   border: "none"

}

.box-shadow-see-all {
   box-shadow: 0 1px 2px rgb(0 0 0 / 0.5);
   border: "none";
   width: 90%;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #00ADFF;
   font-weight: bold;
   padding-top: 8px;
   padding-bottom: 8px;
}

.home-header {
   padding: 10px;
   border-bottom: 1px solid rgb(202, 200, 200);
   font-size: 18px;
   background-color: white;
   font-weight: bold;
   height: 50px;
   margin-left: 16;
   display: flex;
   align-items: center;
   z-index: 2000;
   position: fixed;
   top: 0;
   width: 100%;
}

.home-history-header {
   padding: 10px;
   border-bottom: 1px solid rgb(202, 200, 200);
   font-size: 18px;
   background-color: white;
   font-weight: bold;
   height: 50px;
   margin-left: 16;
   display: flex;
   align-items: center;
   justify-content: space-between;
   z-index: 2000;
   position: fixed;
   top: 0;
   width: 100%;
}

.qr-header {
   padding: 10px;
   border-bottom: 1px solid rgb(202, 200, 200);
   font-size: 18px;
   background-color: white;
   font-weight: bold;
   height: 50px;
   margin-left: 16;
   display: flex;
   align-items: center;
   justify-content: space-between;
   z-index: 2000;
   position: fixed;
   top: 0;
   width: 100%;
}

#form-date-control {
   background-color: #EFF2F5;
   border: "1px solid";
   border-radius: 4px;
   padding-top: 5px;
   padding-bottom: 5px;

   margin-bottom: 10px;
   z-index: 0;
}

#form-date-control:focus {
   border-color: #86B7FE;
}

video {
   max-width: 100%;
   max-height: 100%;
}

/*box scan size*/
.scanner-container {
   position: relative;
   height: 80vh;
   width: 100%;
}

.overlay-element {
   position: absolute;
   top: 0;
   width: 100%;
   height: 100%;
   background: rgba(30, 30, 30, 0.5);
   -webkit-clip-path: polygon(0% 0%,
         0% 100%,
         20% 100%,
         20% 20%,
         80% 20%,
         80% 80%,
         20% 80%,
         20% 100%,
         100% 100%,
         100% 0%);
   clip-path: polygon(0% 0%,
         0% 100%,
         20% 100%,
         20% 20%,
         80% 20%,
         80% 80%,
         20% 80%,
         20% 100%,
         100% 100%,
         100% 0%);
}

.laser {
   width: 60%;
   margin-left: 20%;
   background-color: tomato;
   height: 1px;
   position: absolute;
   top: 40%;
   z-index: 2;
   box-shadow: 0 0 4px red;
   -webkit-animation: scanning 2s infinite;
   animation: scanning 2s infinite;
}

@-webkit-keyframes scanning {
   50% {
      -webkit-transform: translateY(75px);
      transform: translateY(75px);
   }
}

@keyframes scanning {
   50% {
      -webkit-transform: translateY(75px);
      transform: translateY(75px);
   }
}

.qr-background {
   background: linear-gradient(30.09deg, #16AFF1 3.61%, #8FDDFF 28.22%, #0AB5FE 49.35%, #2CC0FF 61.95%, #A4E4FF 70.78%, #17B2F9 83.84%);
   padding: 10px;
   border-radius: 6px;
}

#dtp-filter-custom {
   display: block;
   width: 100%;
   height: calc(1.5em + 0.75rem + 2px);
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   border-left: none;
   border-top-right-radius: 6px;
   border-bottom-right-radius: 6px;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#dtp-filter-custom:focus {
   color: #495057;
   background-color: #fff;
   border-color: #80bdff;
   outline: 0;
   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
 }
.custom-appbar .back-icon:active {
    background-color: rgb(185, 179, 179);
}

.custom-appbar {
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 17px;
    z-index: 2000;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.card-box {
    width: 100%;
    height: 216px;
    background: linear-gradient(135deg, #31B0E7 0%, #008AE9 100%);
    border-radius: 16px;
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.scan-box {
    background-color: #F8F8F8;
    width: 130px;
    height: 120px;
    position: absolute;
    bottom: -50px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-box-mastercard {
    width: 100%;
    height: 216px;
    background: #000D26;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-box-visa {
    width: 100%;
    height: 216px;
    background: #1A1F5F;
    border-radius: 16px;
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-box-jcb {
    width: 100%;
    height: 216px;
    background: #2C3B47;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-box-amex {
    width: 100%;
    height: 216px;
    background: #112A4A;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-box-unionpay {
    width: 100%;
    height: 216px;
    background: #1A6E90;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-box-discover-diners {
    width: 100%;
    height: 216px;
    background: #F78222;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
 #expiry-control {
   background-color: #EFF2F5;
   color: #101010;
   border: none;
   height: 44px;
   font-weight: bold;
   width: 44%;
 }

 .number-format-cus:focus {
   color: #212529;
   background-color: #FFF;
   border-color: #86B7FE;
   outline: 0;
   /* box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25); */
 }

 .mb-3 .input-icon-lak {
   color: #EB001B;
   font-size: 18px;
   font-weight: bold;
   background-color: #F8F8F8;
   border: none;
   width: 15%;
 }
 /* kyc */
 .kyc-header {
    padding: 10px;
    border-bottom: 1px solid rgb(202, 200, 200);
    display: flex;
    justify-content: space-between;
    background-color: white;
    position: fixed;
    z-index: 2000;
    height: 50px;
    top: 0;
    width: 100%;
    overflow: hidden;
}

.kyc-signup-header {
    padding: 10px;
    border-bottom: 1px solid rgb(202, 200, 200);
    font-size: 18px;
    background-color: white;
    font-weight: bold;
    height: 50px;
    margin-left: 16;
    display: flex;
    align-items: center;
    z-index: 2000;
    position: fixed;
    top: 0;
    width: 100%;
}

.header-reload {
    padding-top: 4px;
    padding-bottom: 4px;
    color: white;
    display: flex;
    width: 69px;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(146, 144, 144);
    border-radius: 12px;
}

video {
    width: 100%    !important;
    height: 100%   !important;
    object-fit: fill;
}

.fa-globe {
    text-shadow: 3px 6px rgba(255,165,0,.75)
}

.kyc-logo {
    width: 70%;
    height: 40%;
}

/* kyc signup  */

.kyc-signup-image {
    width: 132px;
    height: 134px;
}


 #frm-control {
    width: 100%;
    background-color: #EFF2F5;
    margin-bottom: 10px;
    z-index: 0;
}

.frm-radio {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

#dtp-dob {
    background-color: #EFF2F5;
    border-left: none;
    border-right: none;
}

#dtp-filter {
    background-color: #EFF2F5;
}

.image-wrapper {
    width:50%;
    border-radius: 4px;
     margin-right: 6px;
    position: relative;
    z-index: 0;
}
.image-wrapper label {
    position: absolute;
    font-size: 14px;
    background-color: #00ADFF;
    color: white;
    padding: 2px 6px;
    border-top-left-radius: 4px;
}


/* kyc signup detail  */

/* .kyc-header {
    overflow: hidden;
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
} */

.detail-info {
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 10px;
    margin-bottom: 14px;
    border-bottom: 1px solid #697D95;
}


/* kyc wait */
.main-slider {
    padding: 0px 12px;
    margin-bottom: 20px;
}











 .pin-button {
    height: 88px; 
    width: 88px;
    background-color: #EFEFEF;
    border-radius: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
}


/* @font-face {
  font-family: "NotoSansLao";
  src: local("NotoSansLao"), 
       url(/assets/fonts/NotoSansLao/NotoSansLao-Regular.ttf) format("truetype");
}

*,
body,
.Dialogbox,
div {
  margin: 0;
  font-family: "NotoSansLao";
} */

@font-face {
  font-family: "NotoSerifRegular";
  src: url(/assets/fonts/NotoSerifLao/NotoSerifLao-Regular.ttf);
}

* {
  font-family: "NotoSerifRegular";
  /* -webkit-user-select: none; */
  /* -webkit-touch-callout: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  /* user-select: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
