/* @font-face {
  font-family: "NotoSansLao";
  src: local("NotoSansLao"), 
       url(/assets/fonts/NotoSansLao/NotoSansLao-Regular.ttf) format("truetype");
}

*,
body,
.Dialogbox,
div {
  margin: 0;
  font-family: "NotoSansLao";
} */

@font-face {
  font-family: "NotoSerifRegular";
  src: url(/assets/fonts/NotoSerifLao/NotoSerifLao-Regular.ttf);
}

* {
  font-family: "NotoSerifRegular";
  /* -webkit-user-select: none; */
  /* -webkit-touch-callout: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  /* user-select: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}