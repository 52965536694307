 #expiry-control {
   background-color: #EFF2F5;
   color: #101010;
   border: none;
   height: 44px;
   font-weight: bold;
   width: 44%;
 }

 .number-format-cus:focus {
   color: #212529;
   background-color: #FFF;
   border-color: #86B7FE;
   outline: 0;
   /* box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25); */
 }

 .mb-3 .input-icon-lak {
   color: #EB001B;
   font-size: 18px;
   font-weight: bold;
   background-color: #F8F8F8;
   border: none;
   width: 15%;
 }