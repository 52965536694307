.card-box {
    width: 100%;
    height: 216px;
    background: linear-gradient(135deg, #31B0E7 0%, #008AE9 100%);
    border-radius: 16px;
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.scan-box {
    background-color: #F8F8F8;
    width: 130px;
    height: 120px;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-box-mastercard {
    width: 100%;
    height: 216px;
    background: #000D26;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-box-visa {
    width: 100%;
    height: 216px;
    background: #1A1F5F;
    border-radius: 16px;
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-box-jcb {
    width: 100%;
    height: 216px;
    background: #2C3B47;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-box-amex {
    width: 100%;
    height: 216px;
    background: #112A4A;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-box-unionpay {
    width: 100%;
    height: 216px;
    background: #1A6E90;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-box-discover-diners {
    width: 100%;
    height: 216px;
    background: #F78222;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}